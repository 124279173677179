<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4>{{ pixel.name }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
        <div class="head-card-bottom">
          <!-- <b-button
            v-b-tooltip.hover.top="$t('Statistics')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            :to="{ path: `/users/${this.$route.params.userId}/pixels/${this.$route.params.id}/stats`}"
          >
            <feather-icon
              icon="BarChart2Icon"
              size="16"
            />
          </b-button> -->
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ path: `/users/${this.$route.params.userId}/pixels/${this.$route.params.id}/edit`}"
          >
            <feather-icon
              icon="SettingsIcon"
              size="16"
            />
            {{ $t('Settings') }}
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import {
  BCard,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    Breadcrumbs,
    BButton,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      pageTitle: '',
      pageSubtitle: '',
      account: {},
      min,
      required,
      integer,
      pixel: [],
      breadcrumbItems: [],
      status: {},
      all_pages: [],
      ...options,
    }
  },
  created() {
    this.getPixelData()
  },
  methods: {
    async getPixelData() {
      const responseData = await useJwt.getPixel(this.$route.params.id)
      this.pixel = Object.freeze(responseData.data.pixel) || []
      if (this.pixel.status !== '') {
        const statusText = this.pixel.status.charAt(0).toUpperCase() + this.pixel.status.slice(1)
        this.status = { value: this.pixel.status, text: statusText }
      }

      if (this.pixel.all_pages) {
        this.all_pages.push(1)
      } else {
        this.all_pages = null
      }
      this.getUserData()
    },
    pixelUpdate() {
      this.$refs.validationPixelRules.validate().then(success => {
        if (success) {
          this.pixel.status = this.status.value
          this.pixel.all_pages = this.all_pages
          useJwt.setPixel(this.$route.params.id, { pixel: this.pixel })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pixel saved',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: this.$t('pixels.pixels'),
          to: `/users/${this.$route.params.userId}/pixels`,
        },
        {
          text: await this.pixel.name,
          active: true,
        },
      ]
    },
  },
}
</script>
